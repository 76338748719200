import React from 'react';
import '../css/information.css';  // Importera information-specifik CSS

function Information() {
    return <div className="information-page">
        <h1 className="information-title">Informationsinnehåll här</h1>
    </div>;
}

export default Information;
